import React from 'react'
import { graphql } from 'gatsby'

import AboutPageTemplate from 'AboutPage'

const AboutPage = ({ data }) => {
  const { markdownRemark: page } = data

  const {
    html,
    excerpt,
    frontmatter
  } = page

  const {
    featuredEpisodes,
    featuredEpisodesTitle,
    mediaBlocks,
    sponsors,
    sponsorsTitle,
    subtitle,
    title,
    videoMp4Url,
    videoThumbnail
  } = frontmatter

  // Should write sanitizer.js to handle this data.
  const sanitizedFeaturedEpisodes = featuredEpisodes.map(({ frontmatter, fields }) =>
    Object.assign(frontmatter, fields)
  )

  return (
    <AboutPageTemplate
      content={html}
      excerpt={excerpt}
      featuredEpisodes={sanitizedFeaturedEpisodes}
      featuredEpisodesTitle={featuredEpisodesTitle}
      mediaBlocks={mediaBlocks}
      sponsors={sponsors}
      sponsorsTitle={sponsorsTitle}
      subtitle={subtitle}
      title={title}
      videoMp4Url={videoMp4Url}
      videoThumbnail={videoThumbnail}
    />
  )
}

export default AboutPage

export const PageQuery = graphql`
  query {
    markdownRemark(
      frontmatter: {
        templateKey: {
          eq: "pages/about"
        }
      }
    ) {
      id
      html
      excerpt
      frontmatter {
        featuredEpisodes {
          fields {
            slug
          }
          frontmatter {
            title
            audioUrl
            audioDuration
            publishDate(formatString: "MMMM D, YYYY")
            show {
              fields {
                slug
              }
              frontmatter {
                title
                artwork
              }
            }
          }
        }
        featuredEpisodesTitle
        mediaBlocks {
          image
          body
          layoutType
        }
        sponsors {
          name
          logo
        }
        sponsorsTitle
        subtitle
        title
        videoMp4Url
        videoThumbnail
      }
    }
  }
`
