import React from 'react'

import Image from 'Image'
import Hero from 'Hero'
import { HTMLContent } from 'Content'
import EpisodeRoll from 'EpisodeRoll'

import AboutMediaBlocks from './AboutMediaBlocks'
import AboutSponsors from './AboutSponsors'

const AboutPageView = ({ content, featuredEpisodes, featuredEpisodesTitle, mediaBlocks, sponsors, sponsorsTitle, subtitle, title, videoMp4Url, videoThumbnail }) => {
  return (
    <>
      <h1 className='u-hide-visually'>{title}</h1>
      <Hero
        leader={`<strong>${subtitle}</strong>`}
      />
      <div className='o-container u-padding-bottom-huge'>
        <div className='o-layout o-layout--rtl c-about__row'>
          <div className='o-layout__item c-about__col u-width-1/2@md u-width-3/5@lg u-padding-top-large u-padding-top-none@md u-padding-left-large@lg u-margin-bottom-large u-margin-bottom-none@md'>
            <div className='c-about__video'>
              {/* eslint-disable */}
              <video
                src={videoMp4Url}
                poster={Image.makeUrl({ src: videoThumbnail, width: 800 })}
                className='u-width-1/1'
                controls
              />
              {/* eslint-enable */}
            </div>
          </div>
          <div className='o-layout__item c-about__col u-width-1/2@md u-width-3/5@lg u-margin-bottom-large u-margin-bottom-none@md'>
            <div className='u-padding-right-large@lg'>
              <HTMLContent content={content} className='c-about__rte' />
            </div>
          </div>
        </div>
        <AboutMediaBlocks mediaBlocks={mediaBlocks} />
      </div>

      <AboutSponsors
        sponsors={sponsors}
        sponsorsTitle={sponsorsTitle}
      />

      {featuredEpisodes.length > 0 && (
        <>
          <div className='o-container'>
            <div className='c-about__rte u-ta-c u-margin-bottom-large'>
              {featuredEpisodesTitle}
            </div>
          </div>
          <div
            className='c-about__episodes u-bgcolor-grey u-color-white'
          >
            {
              featuredEpisodes &&
                <EpisodeRoll
                  title=''
                  episodes={featuredEpisodes}
                />
            }
          </div>
        </>
      )}
    </>
  )
}

export default AboutPageView
