import React from 'react'
import Swiper from 'react-id-swiper'

import Image from 'Image'

const AboutSponsors = ({ sponsors, sponsorsTitle }) => {
  const swiperProps = {
    loop: true,
    centeredSlides: true,
    grabCursor: true,
    slidesPerView: 'auto',
    spaceBetween: 56,
    autoplay: {
      delay: 3000
    },
    breakpoints: {
      768: {
        spaceBetween: 27
      }
    }
  }

  if (sponsors.length === 0) {
    return null
  }

  return (
    <div className='u-padding-top-large u-padding-top-huge@md'>
      <div className='o-container'>
        <h4
          className='c-about__rte u-ta-c u-br@md u-margin-bottom-large u-margin-bottom-massive@md'
          dangerouslySetInnerHTML={{ __html: sponsorsTitle }}
        />
      </div>
      <div className='c-sponsors u-margin-bottom-large u-margin-bottom-massive@md'>
        <Swiper
          {...swiperProps}
        >
          {sponsors.map((sponsor, sponsorIndex) => {
            return (
              <div key={sponsorIndex} className='c-sponsors__item'>
                <Image
                  image={sponsor.logo}
                  width={200}
                  alt={sponsor.name}
                />
              </div>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default AboutSponsors
