import React from 'react'

import Layout from 'Layout'
import Seo from 'SEO'
import { HTMLContent } from 'Content'
import View from './AboutPageView'

const AboutPage = ({ content, excerpt, featuredEpisodes, featuredEpisodesTitle, mediaBlocks, sponsors, sponsorsTitle, subtitle, title, videoMp4Url, videoThumbnail }) => (
  <Layout>
    <Seo
      title={title}
      description={excerpt}
    />
    <View
      content={content}
      contentComponent={HTMLContent}
      featuredEpisodes={featuredEpisodes}
      featuredEpisodesTitle={featuredEpisodesTitle}
      mediaBlocks={mediaBlocks}
      sponsors={sponsors}
      sponsorsTitle={sponsorsTitle}
      subtitle={subtitle}
      title={title}
      videoMp4Url={videoMp4Url}
      videoThumbnail={videoThumbnail}
    />
  </Layout>
)

export default AboutPage
