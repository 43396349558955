import React from 'react'
import classNames from 'classnames'
import Showdown from 'showdown'

import { HTMLContent } from 'Content'
import Image from 'Image'

const Converter = new Showdown.Converter()

const AboutMediaBlocks = ({ mediaBlocks }) => {
  return mediaBlocks.map((mediaBlock, mediaBlockIndex) => {
    if (mediaBlock.layoutType === 'ittb') {
      return (
        <div
          key={mediaBlockIndex}
          className={`c-about-mb c-about-mb--${mediaBlock.layoutType}`}
        >
          <div className='c-about-mb__ittb-img u-margin-bottom-large'>
            <Image
              image={mediaBlock.image}
              width={682}
              sizes={[
                `${Image.breakpoint('md')} calc(100vw - 54px)`,
                '682px'
              ]}
              lazyload
            />
          </div>
          <div className='u-margin-bottom-large'>
            <HTMLContent content={Converter.makeHtml(mediaBlock.body)} className='c-about__rte' />
          </div>
        </div>
      )
    }
    return (
      <div
        key={mediaBlockIndex}
        className={classNames(`o-layout c-about__row c-about-mb c-about-mb--${mediaBlock.layoutType}`, {
          'o-layout--rtl': (mediaBlock.layoutType === 'tlir')
        })}
      >
        <div className='o-layout__item u-margin-bottom-large u-margin-bottom-none@md'>
          <div className={classNames({
            'u-padding-right-large@lg': (mediaBlock.layoutType === 'iltr'),
            'u-padding-left-large@lg': (mediaBlock.layoutType === 'tlir')
          })}>
            <Image
              image={mediaBlock.image}
              width={683}
              sizes={[
                `${Image.breakpoint('md')} calc(100vw - 54px)`,
                `${Image.breakpoint('lg')} calc(((100vw - 27px) / 2) - 27px)`,
                `${Image.breakpoint('container')} calc(((100vw - 27px) / 2) - 68px)`,
                '683px'
              ]}
              lazyload
            />
          </div>
        </div>
        <div className='o-layout__item u-margin-bottom-large u-margin-bottom-none@md'>
          <div className={classNames({
            'u-padding-left-large@lg': (mediaBlock.layoutType === 'iltr'),
            'u-padding-right-large@lg': (mediaBlock.layoutType === 'tlir')
          })}>
            <HTMLContent content={Converter.makeHtml(mediaBlock.body)} className='c-about__rte' />
          </div>
        </div>
      </div>
    )
  })
}

export default AboutMediaBlocks
